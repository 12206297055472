import React from 'react';
import {Link} from 'gatsby';
import styles from './postLink.module.css';

const PostLink = ({post}) => (
  <Link to={post.frontmatter.path} className={styles.title}>
    {post.frontmatter.title}
  </Link>
);

export default PostLink;
