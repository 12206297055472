import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostLink from '../components/postLink';

const BlogPage = ({
  data: {
    allMarkdownRemark: {edges},
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => (
      <PostLink key={edge.node.id} post={edge.node} excerpt={edge.node.excerpt} />
    ));

  return (
    <Layout>
      <SEO title="Blog" />
      <h2>Blog</h2>
      <div>{Posts}</div>
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            preview
          }
        }
      }
    }
  }
`;

export default BlogPage;
